


export function loginWithTwitch( state ) 
{
    var payload = {}

    payload.client_id = "rslxflt3v5q7dmdd4y01zv5qbp0o0g"
    payload.scope = "user:read:email user:read:follows"
    payload.redirect_uri = "https://spice.stream/oauth2/twitch/"
    payload.response_type = "code"
    payload.state = state

    var str = Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');

    window.location.replace("https://id.twitch.tv/oauth2/authorize?" + str)
}


export function loginWithYoutube( state )
{
    var payload = {}
    payload.client_id = "544343971681-g8sncl9b2g28qtgs75unj9d6v7qmei3l.apps.googleusercontent.com"
    payload.scope = "https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
    payload.redirect_uri = "http://localhost:3000/oauth2/google/"
    payload.response_type = "code"
    payload.state = state

    var str = Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');

    window.location.replace("https://accounts.google.com/o/oauth2/v2/auth?" + str )

}
