



export function TitledBox( props )
{
    const title = props.title

    return (
        <>
            <div style={{ width:'100%', height:'30px', fontSize:16 }}>
                <b>{title}</b>
            </div>
            <div style={{ width:'100%' }}>
                { props.children }
            </div>
        </>
    )
}

