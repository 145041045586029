import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button'

import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
//import { login_twitch } from '../../Utils/Authentication'
import { useNavigate } from 'react-router-dom';

import { backendAccessPost, backendLoginTwitch, backendLoginGoogle } from '../../Utils/Authentication';

export default function GoogleLogin( props )
{
    const [ payload ] = useSearchParams()

    const code = payload.get("code")
    const scope = payload.get("scope")
    const state = payload.get("state")
    const error = payload.get("error")
    const error_description = payload.get("error_description")

    const callOnce = useRef(false)

    let navigate = useNavigate()

    useEffect( () => {
        if( callOnce.current )
        {
            return
        }

        callOnce.current = true

        if( error == null )
        {
            console.log( code, scope, state, error, error_description )

            
            if( state == "accounts" )
            {
                let data = {}
                data["code"] = code 

                backendAccessPost('/api/accounts/gogole/fetch-token/', data ).then( res => {
                    if( state == "accounts")
                    {
                        navigate("/internet-accounts",{state:{state:"5"}})
                    }
                })
            }
            else if( state == "login" )
            {
                backendLoginGoogle( code ).then( res => {
                    navigate("/profile")
                })
            }
        }
    },[])

    const backToAccounts = () => {
        if( state == "accounts" )
        {
            navigate("/internet-accounts")
        }
        navigate("/profile")

    }


    return (
        <>
        { error && 
            <>
                <div>
                    <Alert variant="warning"> { error_description } </Alert>
                </div>
                <div>
                    <Button onClick={backToAccounts}>Back to Accounts</Button>
                </div>
            </>
        }
        </>
    )
}
