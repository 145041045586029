import { useState, useEffect, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import Form from 'react-bootstrap/Form'

import { stringToHash } from '../Misc/FileTools'

import { LiaPepperHotSolid } from "react-icons/lia";
import { AiFillFileAdd } from "react-icons/ai";
import { FaFaceLaugh } from "react-icons/fa6";
import { BiSolidUserRectangle } from "react-icons/bi";
import { PiTextTFill } from "react-icons/pi";

export function ItemButton( props )
{
    const name = props.name
    const selected = props.selected 
    const setSelected = props.setSelected
    const disabled = ( props.disabled == null ) ? false : props.disabled
    
    const [ isHover, setIsHover ] = useState( false )

    if( disabled )
    {
        return (
            <div className="d-flex justify-content-center align-items-center"
                 style={{ height:'60px', width:'100%',
                          border:'',
                          borderRadius:'5px',
                          backgroundColor:'',
                          padding:'10px',
                          color:'grey',
                          ...props.style }}
            >
                {props.children}
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-center align-items-center"
             style={{ height:'60px', width:'100%',
                      border:(isHover) ? '0.2px solid #DADADA' : '',
                      borderRadius:'5px',
                      backgroundColor:(selected == name) ? '#DADADA' : '',
                      padding:'10px',
                      ...props.style }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
             onClick={ e => setSelected(name)}
        >
            {props.children}
        </div>
    )
}

export function ModalBody( props )
{
    const addCloseModal = props.addCloseModal
    const closeModal = props.closeModal 
    const accountType = props.accountType
    
    const [ selected, setSelected ] = useState("")

    const file = useRef(null)
    //const [ file, setFile ] = useState(null)
    const [ fileValue, setFileValue ] = useState("")

    const fileSetter = ( v,f ) =>
    {
        setFileValue( v )
        file.current = f

        var fr = new FileReader();

        fr.onload = function() {
            let content =  btoa(fr.result)

            var data = {}
            data.content = content
            data.filename = file.current.name
            data.hash = stringToHash(content)

            //uploadCloseModal( data )
            file.current = data
        }

        fr.readAsBinaryString(file.current);
    }

    const upload = () => {
        if( selected == "" )
        {
            return
        }

        if( file.current == null && selected=="image" )
        {
            closeModal()
            return
        } 

        addCloseModal( selected, file.current )
    }

    return (
        <>
            <Modal.Body>
                <div style={{ width:'100%', height:'290px', padding:'10px'}}>
                    <ItemButton name="image" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }}>
                        <Form.Control type="file" value={fileValue} 
                              onChange={(e) => fileSetter( e.target.value, e.target.files[0])}
                              size="sm"
                              style={{ maxWidth:'250px'}}
                        />
                    </ItemButton>

                    <ItemButton name="avatar" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }} disabled={accountType=='mild'}>
                        Avatar <LiaPepperHotSolid style={{ marginLeft:'10px'}} />
                    </ItemButton>

                    <ItemButton name="emoticon" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }} disabled={accountType=='mild'}>
                        Emoticon <LiaPepperHotSolid style={{ marginLeft:'10px'}}/>
                    </ItemButton> 
                    <ItemButton name="text" selected={selected} setSelected={setSelected}
                                style={{ marginBottom:'10px' }} disabled={accountType=='mild'}>
                        Text <LiaPepperHotSolid style={{ marginLeft:'10px'}}/>
                    </ItemButton>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ border:'none'}}>
                <Button variant="contained" onClick={closeModal} 
                        style={{ marginRight:'5px', textTransform:'none' }} 
                        size="small">Close</Button>
                <Button variant="contained" onClick={upload} style={{ textTransform:'none' }}
                        size="small">Add</Button>
            </Modal.Footer>
        </>
    )
}

export function ButtonFace( props )
{
    const title1 = props.title1 
    const title2 = props.title2
    const icon = props.icon
    const hoverColor = props.hoverColor 

    const [ isHover, setIsHover ] = useState(false)

    return (
        <div style={{ height:'100%', width:'100%', fontSize:11 }}
             onMouseEnter={ e => setIsHover(true)}
             onMouseLeave={ e => setIsHover(false)}
        >
            <div style={{ color: (isHover) ? hoverColor : '#000000' }}>
                <b>{ title1 }</b>
            </div>
            <div>
                { title2 }
            </div>
            <div style={{ fontSize:50, color: (isHover) ? hoverColor : '#000000' }}>
                { icon }
            </div>
            
        </div>
    )
}

export default function AddLayerModal( props )
{
    const showModal = props.showModal 
    const closeModal = props.closeModal 
    const addCloseModal = props.addCloseModal 
    const accountType = props.accountType

    const [ selectedFile, setSelectedFile ] = useState(null)

    const inputRef = useRef(null)

    const update = ( name ) => {
        addCloseModal( name, null )
    }

    useEffect( () => {
        if( showModal )
        {
            setSelectedFile(null)
        }
    },[showModal])

    const openFileDialog = () => {
        inputRef.current = document.createElement('input');
        inputRef.current.type = 'file';
        inputRef.current.onchange = _this => {
            let file = inputRef.current.files[0];
            setSelectedFile(file)
        };
        inputRef.current.click();
    }

    useEffect( () => {
        if(selectedFile == null)
        {
            return
        }

        console.log( selectedFile )

        var fr = new FileReader();

        fr.onload = function() {
            let content =  btoa(fr.result)

            var data = {}
            data.content = structuredClone(content)
            data.filename = structuredClone(selectedFile.name)
            data.hash = stringToHash(content)

            addCloseModal( "image", data )
        }

        fr.readAsBinaryString(selectedFile);
    }, [selectedFile])

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Body style={{height:'270px'}}>
                <div className="d-flex justify-content-center align-items-center fw-light" 
                     style={{ height:'50px', width:'100%', fontSize:12}}>
                    Add a new layer
                </div>

                <div style={{ height:'150px', width:'100%' }}>
                    <div className="d-flex" style={{ height:'100%', width:'100%'}}>
                        <Button style={{ width:'25%', textTransform:'none', color:'#000000'}}
                                onClick={openFileDialog}>
                            <ButtonFace title1="Place an image" title2="Browse files"
                                        icon={<AiFillFileAdd />}/>
                        </Button>
                        <Button style={{ width:'25%', textTransform:'none', 
                                         color:(accountType=="mild") ? 'none':'#000000'
                                    }} 
                                disabled={accountType=="mild"}
                                onClick={ e => update("avatar") }>
                            <ButtonFace title1="Place an avatar" title2="of the viewers"
                                        icon={<BiSolidUserRectangle />}
                                        hoverColor="blue"/>

                        </Button>
                        <Button style={{ width:'25%', textTransform:'none', 
                                         color:(accountType=="mild") ? 'none':'#000000'
                                    }} 
                                disabled={accountType=="mild"}
                                onClick={ e => update("emoticon") }>
                            <ButtonFace title1="Place an emoji" title2="From chat"
                                        icon={<FaFaceLaugh />}
                                        hoverColor="red"/>

                        </Button>
                        <Button style={{ width:'25%', textTransform:'none', 
                                         color:(accountType=="mild") ? 'none':'#000000'
                                    }} 
                                disabled={accountType=="mild"}
                                onClick={ e => update("text") }>
                            <ButtonFace title1="Place an text" title2="From chat"
                                        icon={<PiTextTFill />}
                                        hoverColor="green"/>
                        </Button>
                        <Button style={{ width:'25%', textTransform:'none', 
                                         color:(accountType=="mild") ? 'none':'#000000'
                                    }} 
                                disabled={accountType=="mild"}
                                onClick={ e => update("dyno") }>
                            <ButtonFace title1="Place dyno" title2="From chat"
                                        icon={<PiTextTFill />}
                                        hoverColor="orange"/>
                        </Button>

                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center" 
                     style={{ height:'50px', width:'100%', fontSize:12}}>
                    { accountType=="mild" &&
                    <div style={{color:'#FF0600', cursor:'pointer'}}><b><u>Go Premium</u></b></div>
                    }
                </div>

                
            </Modal.Body>
            {/*<Modal.Header closeButton style={{ border:'none' }}>
                <Modal.Title style={{ fontSize:'20px'}}>Add Layer</Modal.Title>
            </Modal.Header>
            { showModal &&
            <ModalBody addCloseModal={addCloseModal} closeModal={closeModal} accountType={accountType} />
            }
            */}
        </Modal>
    )
}

