import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../Modules/General"

import FontModule from "../Modules/FontModule"
import VariableModule from "../Modules/VariableModule"

const randomPickerVariables = [
    {name:'Output', key:'output', type:'W'},
]

export function RandomPickerProperties( props )
{
    const cfg = props.cfg 
    const setCfg = props.setCfg 
    const modalOpen = props.modalOpen
    const selected = props.selected
    const reset = props.reset

    const [ source, setSource ] = useState(null)
    const [ presetList, setPresetList ] = useState(null)
    const [ font, setFont ] = useState(null)
    const [ variables, setVariables ] = useState(null)

    const [ showModal, setShowModal ] = useState(false)


    const [ modalText, setModalText ] = useState("")

    const reload = () => {
        setFont( (cfg.font) ? cfg.font : {})
        setVariables( (cfg.variables) ? cfg.variables : {} )
        setPresetList( (cfg.preset_list) ? cfg.preset_list : [] ) 
    }

    useEffect( () => {
        reload()
    },[])

    useEffect( () => {
        if( font == null || variables == null || presetList == null )
        {
            return
        }

        let data = {}
        data.font = font 
        data.variables = variables
        data.preset_list = presetList

        setCfg(data)
            
    },[ font, variables, presetList ])

    useEffect( () => {
        modalOpen.current = showModal
    },[showModal])

    const addToPreset = () => {
        if( modalText == "" )
        {
            return 
        }

        let pp = structuredClone(presetList)
        pp.push( modalText )

        setPresetList(pp)

        setModalText("")
    }

    const removeFromPresetList = (idx) => {
        let pp = structuredClone(presetList)
        pp.splice(idx,1)
        setPresetList(pp)
    }

    return (
        <>
            <SettingsGroup name="Data source">
                <div className="d-flex align-items-center justify-content-center m-1" >
                    <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '100%', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    <Button onClick={ e => setShowModal(true) }
                            size="sm" 
                            style={{ width:'100%', textTransform:'none' }}
                            size="small"
                    > 
                        Edit preset list
                    </Button>
                    </div>
                </div>

            </SettingsGroup>
            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
                        reset={reset}
            />
            }
            
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={randomPickerVariables}
                            reset={reset}
            />
            }
            
            <Modal show={showModal} onHide={e => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Preset List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <InputGroup size="sm">
                            <Form.Control value={modalText} onChange={ e => setModalText(e.target.value)} />
                            <Button variant="contained"
                                    style={{ marginRight:'5px', textTransform:'none' }}
                                    onClick={addToPreset}
                            >
                                Add
                            </Button>
                        </InputGroup>
                    </div>
                    <div style={{ width:'100%', height:'250px', overflowY:'scroll', padding:'10px'}}>
                    { presetList &&
                    <>
                    { presetList.map( (pp,idx) => 
                    <div key={idx}>
                        {pp} [<a href="#" onClick={ e => removeFromPresetList(idx)}>Remove</a>]
                    </div>
                    )}
                    </>
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={e => setShowModal(false)} 
                            size="sm" style={{ marginRight:'5px', textTransform:'none' }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

const twitchAudiencePickerVariables = [
    {name:'Output', key:'output', type:'W'},
    {name:'Count', key:'count', type:'W'},
]

export function TwitchAudiencePickerProperties( props )
{
    const cfg = props.cfg 
    const setCfg = props.setCfg 
    const selected = props.selected
    const reset = props.reset

    const [ source, setSource ] = useState(null)
    const [ hashtag, setHashtag ] = useState(null)
    const [ font, setFont ] = useState(null)
    const [ variables, setVariables ] = useState(null)

    const reload = () => {
        setFont( (cfg.font) ? cfg.font : {})
        setVariables( (cfg.variables) ? cfg.variables : {} )
        setHashtag( (cfg.hashtag) ? cfg.hashtag : "" )
    }

    useEffect( () => {
        reload()
    },[])

    useEffect( () => {
        if( font == null || variables == null || hashtag == null )
        {
            return
        }

        let data = {}
        data.font = font 
        data.variables = variables
        data.hashtag = hashtag

        setCfg(data)
            
    },[ font, variables, hashtag ])

    return (
        <>
            <SettingsGroup name="Data source"> 
                <Control name="Hashtag"
                     ctrl={
                        <Form.Control style={{ width:'100%'}} size="sm" 
                             value={hashtag} 
                             onChange={ e => setHashtag( e.target.value )}
                        />
                     }
            />

            </SettingsGroup>
            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
                        reset={reset}
            />
            }
            
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={twitchAudiencePickerVariables}
                            reset={reset}
            />
            } 
        </>
    )
}

const lottaryVariables = [
    {name:'Output', key:'output', type:'A'},
    {name:'Count', key:'count', type:'W'},
    {name:'Value', key:'value', type:'W'}
]

export function LottaryProperties( props )
{
    const cfg = props.cfg 
    const setCfg = props.setCfg 
    const selected = props.selected
    const reset = props.reset

    const [ source, setSource ] = useState(null)
    const [ font, setFont ] = useState(null)
    const [ variables, setVariables ] = useState(null)
    const [ reference, setReference ] = useState(null)

    const reload = () => {
        setFont( (cfg.font) ? cfg.font : {})
        setVariables( (cfg.variables) ? cfg.variables : {} )
        setReference( (cfg.reference) ? cfg.reference : "" )
    }

    useEffect( () => {
        reload()
    },[])

    useEffect( () => {
        if( font == null || variables == null || reference == null )
        {
            return
        }

        let data = {}
        data.font = font 
        data.variables = variables
        data.reference = reference

        setCfg(data)
            
    },[ font, variables, reference ])

    return (
        <>
            <SettingsGroup name="Data source">
                <Control name="Reference"
                     ctrl={
                        <Form.Control style={{ width:'100%'}} size="sm" 
                             value={reference} 
                             onChange={ e => setReference( e.target.value )}
                        />
                     }
                />

            </SettingsGroup>
            { font &&
            <FontModule font={font} 
                        setFont={setFont}
                        selected={selected}
                        reset={reset}
            />
            }
            
            { variables &&
            <VariableModule variables={variables} 
                            setVariables={setVariables} 
                            variableList={lottaryVariables} 
                            reset={reset}
            />
            } 
        </>
    )
}



export default function DynoProperties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType
    const reset = props.reset

    const [ widgetMode, setWidgetMode ] = useState(null)
    const [ randomPickerCfg, setRandomPickerCfg ] = useState(null)
    const [ twitchAudiencePickerCfg, setTwichAudiencePickerCfg ] = useState(null)
    const [ lottaryCfg, setLottaryCfg ] = useState(null)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        let properties = layersCfg.current[selected].properties

        if( properties == null )
        {
            properties = {}
        }

        setWidgetMode( (properties.widget_mode) ? properties.widget_mode : 'random_picker' )
        setRandomPickerCfg( ( properties.random_picker_cfg ) ? properties.random_picker_cfg : {} )
        setTwichAudiencePickerCfg( (properties.twitch_audience_picker_cfg ) ? properties.twitch_audience_picker_cfg : {} )
        setLottaryCfg( (properties.lottary_cfg ) ? properties.lottary_cfg : {} )
    }

    useEffect( () => {
        reload()
    },[selected])

    useEffect( () => {
        if( widgetMode == null || randomPickerCfg == null || twitchAudiencePickerCfg == null || lottaryCfg == null )
        {
            return
        }

        var data = {
            widget_mode: widgetMode, 
            random_picker_cfg: randomPickerCfg,
            twitch_audience_picker_cfg: twitchAudiencePickerCfg,
            lottary_cfg : lottaryCfg
        }

        layersCfg.current[selected].properties = data

        setLayers([...layers])

    },[widgetMode, randomPickerCfg, twitchAudiencePickerCfg, lottaryCfg ])

    if( widgetMode == null || randomPickerCfg == null || twitchAudiencePickerCfg == null || lottaryCfg == null )
    {
        return (<></>)
    }


    return (
        <>
            <SettingsGroup name="General">
                <div style={{ marginBottom:'10px'}}>
                    <Control name="Widget mode"
                             ctrl={
                        <Form.Select style={{ width:'100%'}} size="sm" 
                             value={widgetMode} 
                             onChange={ e => setWidgetMode( e.target.value )}
                        >
                            <option value="random_picker">Random Picker</option>
                            <option value="twitch_audience_picker">Twitch Audience Picker</option>
                            <option value="lottary">Lottary</option>
                        </Form.Select>
                     }
                    />
                </div>
            </SettingsGroup>

            
            {( widgetMode == 'random_picker' && randomPickerCfg) &&
            <RandomPickerProperties cfg={randomPickerCfg} 
                                    setCfg={setRandomPickerCfg} 
                                    modalOpen={modalOpen} 
                                    selected={selected}
                                    reset={reset}/>
            }
            {( widgetMode == 'twitch_audience_picker' && twitchAudiencePickerCfg ) &&
            <TwitchAudiencePickerProperties cfg={twitchAudiencePickerCfg} 
                                            setCfg={setTwichAudiencePickerCfg} 
                                            selected={selected}
                                            reset={reset}
            />
            }
            {( widgetMode == 'lottary' && lottaryCfg ) &&
            <LottaryProperties cfg={lottaryCfg} 
                               setCfg={setLottaryCfg} 
                               selected={selected}
                               reset={reset}
            />
            }
        </>
    )
}
