import { useEffect, useState } from 'react';
import { CiSquarePlus } from "react-icons/ci";
import { CiSquareMinus } from "react-icons/ci";
import Form from 'react-bootstrap/Form'
import FormRange from 'react-bootstrap/FormRange'
import Slider from '@mui/material/Slider';

export default function ScalarBox(props) 
{
    const value=props.value
    const setValue=props.setValue
    const step=props.step
    const lowerBound = props.lowerBound 
    const upperBound = props.upperBound
    const labelValue = props.labelValue

    const decreaseValue = () => {
        let s = step;

        if( s == null )
        {
            s = 1.0
        }

        setValue(parseInt(value) - s);
    }

    const increaseValue = () => {
        let s = step;

        if( s == null )
        {
            s = 1.0
        }

        setValue(parseInt(value) + s);
    }

    useEffect( () => {
        let v = value 
        let has_change = false

        if( lowerBound != null )
        {
            if( v < lowerBound )
            {
                v = lowerBound 
                has_change = true
            }
        }

        if( upperBound != null )
        {
            if( v > upperBound )
            {
                v = upperBound 
                has_change = true 
            }
        }

        if( has_change )
        {
            setValue(v)
        }


    }, [ value ])

    return (
        <Slider value={value}
                    onChange={ e => setValue(e.target.value) }
                    disableSwap
                    valueLabelDisplay={labelValue}
        />
    ) 
}
