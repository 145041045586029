import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'

import { Control, SettingsGroup } from "../Modules/General"

import FontModule from "../Modules/FontModule"
import VariableModule from "../Modules/VariableModule"

export default function EmoticonProperties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType
    const reset = props.reset

    const [ source, setSource ] = useState(null)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        let properties = layersCfg.current[selected].properties

        if( properties == null )
        {
            properties = {}
        }

        setSource( (properties.source) ? properties.source : "last")
    }

    useEffect( () => {
        reload()
    }, [reset] )

    useEffect( () => {
        if( source == null )
        {
            return
        }

        var data = {
            source: source,
        }

        layersCfg.current[selected].properties = data

        setLayers([...layers])

    },[ source ])

    return (
        <>
            <SettingsGroup name="Data source">
                { source &&
                <Control name="Source"
                     ctrl={
                         <Form.Select style={{width:'100%'}} size="sm" 
                             value={source} 
                             onChange={ e => setSource( e.target.value )}
                        >
                            <option value="last">Last</option>
                            <option value="super_emot_1">Super Emot (1 min)</option>
                            <option value="super_emot_5">Super Emot (5 min)</option>
                        </Form.Select>
                     }
                />
                }
            </SettingsGroup>
        </>
    )
}
