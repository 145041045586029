import { useState, useEffect } from "react";
import { SettingsGroup } from "./General";

import { VariableControl } from "./General";
import Form from 'react-bootstrap/Form'

export function Variable( props )
{
    const data = props.data
    const key = props.id

    const variables = props.variables 
    const setVariables = props.setVariables

    const [ name, setName ] = useState(null)
    const [ value, setValue ] = useState(null)
    const [ type, setType ] = useState(null)

    useEffect( () => {
        setName( data.name )
        setValue( data.value )
        setType( data.type )
    },[data])

    useEffect( () => {
        if( value == null )
        {
            return
        }

        let vv = structuredClone(variables)
        vv[ data.key ] = value 
        setVariables(vv)
    },[value])

    return (
        <VariableControl name={name}
                         value={value}
                         setValue={setValue}
                         type={type}
        />
    )
}

export default function VariableModule( props )
{
    const variables = props.variables 
    const setVariables = props.setVariables 
    const variableList = props.variableList
    const reset = props.reset

    const [ data, setData ] = useState(null)

    const reload = () => {
        let d = []

        for( let variable of variableList )
        {
            let var_ = structuredClone( variable )
            var_.value = structuredClone(variables[ var_.key ])

            if( var_.value == null )
            {
                var_.value = ""
            }
            
            d.push( var_ )
        }

        setData( d )
    }

    useEffect( () => {
        reload()
    },[reset])

    if( data == null )
    {
        return (<></>)
    }

    return (
        <SettingsGroup name="Variables">
            { data.map( (variable,idx) => 
            <Variable data={variable}
                      name={variable.name} 
                      id={variable.key} 
                      key={idx} type={variable.type} 
                      presetValue={variable.value} 
                      variables={variables} 
                      setVariables={setVariables}
            />
            )}
        </SettingsGroup>
    )
}
