
import { useEffect, useState } from 'react'
import { backendAccessPost, backendAccessGet, backendMe, logout } from '../../Utils/Authentication'
import TermsOfUse from '../../Legal/TermsOfUse'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

export function SpiceNameModal( props )
{
    const userConfig = props.userConfig 
    const spiceName = props.spiceName 
    const setSpiceName = props.setSpiceName

    const [ showModal, setShowModal ] = useState( false )
    const [ error, setError ] = useState(null)
    const [ textfield, setTextfield ] = useState(null)

    useEffect( () => {
        if( spiceName != null )
        {
            return
        }

        if( userConfig.terms_of_use == null )
        {
            setShowModal(false )
            return
        }

        if( userConfig.terms_of_use.accepted != null )
        {
            setShowModal( true )
        }
    },[userConfig])

    const verfiySpiceName = () => {
        setError(null)
        if( textfield == null || textfield == "" )
        {
            return
        }

        let data = {}
        data['spice_name'] = textfield 

        backendAccessPost('/api/auth/create-spice-name/', data ).then( res => {
            if( res.error_message )
            {
                setError( res.error_message )
                return
            }

            setSpiceName( textfield )
            setShowModal(false)
        })
    }

    return (
        <Modal show={showModal}>
            <Modal.Header>
                <Modal.Title style={{ width: '100%' }}>
                    <div className="d-flex justify-content-center" 
                         style={{ width:'100%'}}>
                        <img src={'/images/logo-motion-0205_1.gif'} 
                             style={{ width: 100, height: 'auto', bottom: '5px', 
                                      paddingTop: '10px' }} 
                        />
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: '150px', width: '100%', overflowY:'scroll', fontSize:'12px' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ height:'100%', width:'100%' }}>
                    <InputGroup hasValidation>
                    <Form.Control value={textfield} 
                                  onChange={ e => setTextfield(e.target.value)}
                                  placeHolder="Pick a Spice name"
                                  isInvalid={ error != null }
                    />
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>

                </InputGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                    <Button onClick={verfiySpiceName}>Set Spice name</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default function LoadData( props )
{
    const me = props.me 
    const setMe = props.setMe
    const accounts = props.accounts 
    const setAccounts = props.setAccounts 
    const displayName = props.displayName 
    const setDiplayName = props.setDiplayName 
    const avatarImage = props.avatarImage 
    const setAvatarImage = props.setAvatarImage
    const spiceName = props.spiceName
    const setSpiceName = props.setSpiceName

    const userState = props.userState 
    const setUserState = props.setUserState 
    const userStateJson = props.userStateJson

    const userConfig = props.userConfig 
    const setUserConfig = props.setUserConfig 
    const userPoints = props.userPoints 
    const setUserPoints = props.setUserPoints 
    const accountType = props.accountType 
    const setAccountType = props.setAccountType 

    const state = props.state 
    const setState = props.setState

    const [ loading, setLoading ] = useState( true )

    const loadAvatar = async ( avatar_ref ) => {
        let load_avatar = true
        if( localStorage.avatar && avatar_ref )
        {
            let avatar_local = await JSON.parse( localStorage.avatar )
            if( avatar_local.checksum_md5 == avatar_ref.checksum_md5 )
            {
                setAvatarImage( avatar_local.img )
                load_avatar = false
            }
        }

        if( load_avatar )
        {
            let avatar_res = await backendAccessGet('/api/avatar/get-avatar/')

            if( avatar_res != null && avatar_res.avatar )
            {
                setAvatarImage( avatar_res.avatar.img )
                localStorage.avatar = JSON.stringify( avatar_res.avatar )
            }
        }
    }

    useEffect( () => {
        if( !( me == null || accounts == null )  )
        {
            setLoading( false )
            return
        }

        backendMe().then(res => {
            if (res == null) {
                logout()
                return
            }

            setMe(res.me)

            loadAvatar( res.avatar ).then( res => {})

            if( res.state )
            {
                let s = res.staete
                userStateJson.current = JSON.stringify(s)
                setUserState(res.state)
            }
            else
            {
                setUserState({})
            }

            if( res.user_config )
            {
                setUserConfig(res.user_config)
                setAccountType( res.user_config.config.account )
            }
            else 
            {
                setUserConfig({})
            }

            if( res.points )
            {
                setUserPoints( res.points )
            }
            else 
            {
                setUserPoints( {'points':0} )
            }

            if( res.accounts )
            {
                setAccounts( res.accounts )
                if( res.accounts.twitch )
                {
                    setDiplayName( res.accounts.twitch.username )
                }
                else 
                {
                    setDiplayName( res.me.username )
                }
            }

            setSpiceName( res.spice_name )

            setLoading( false )
        })

    },[])

    if( loading )
    {
        <>
            Loading 
        </>

    }


    return (
        <>
        { userConfig &&
        <>
            <TermsOfUse userConfig={userConfig} setUserConfig={setUserConfig}>
                            { props.children }
            </TermsOfUse>
            <SpiceNameModal userConfig={userConfig} spiceName={spiceName} setSpiceName={setSpiceName} />
        </>
        }
        </>
    )   
}
