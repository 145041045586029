import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button'

import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
//import { login_twitch } from '../../Utils/Authentication'
import { useNavigate } from 'react-router-dom';

import { backendAccessPost, backendLoginTwitch } from '../../Utils/Authentication';

export default function TwitchLogin( props )
{
    const [ payload ] = useSearchParams()

    const code = payload.get("code")
    const scope = payload.get("scope")
    const state = payload.get("state")
    const error = payload.get("error")
    const error_description = payload.get("error_description")

    let navigate = useNavigate()

    useEffect( () => {
        if( error == null )
        {

            if( state == "accounts" )
            {
                let data = {}
                data["code"] = code 

                backendAccessPost('/api/accounts/twitch/fetch-token/', data ).then( res => {
                    if( state == "accounts")
                    {
                        navigate("/internet-accounts")
                    }
                })
            }
            else if( state == "login" )
            {
                backendLoginTwitch( code ).then( res => {
                    navigate("/profile")
                })
            }
        }
    },[])

    const backToAccounts = () => {
        if( state == "accounts" )
        {
            navigate("/home",{state:{state:"5"}})
        }
        navigate("/home")

    }


    return (
        <>
        { error && 
            <>
                <div>
                    <Alert variant="warning"> { error_description } </Alert>
                </div>
                <div>
                    <Button onClick={backToAccounts}>Back to Accounts</Button>
                </div>
            </>
        }
        </>
    )
}
