import { useState, useEffect, useRef } from 'react'

import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import InputGroup from "react-bootstrap/InputGroup"
import { RgbaColorPicker } from "react-colorful"
import Button from 'react-bootstrap/Button'

import { LiaPepperHotSolid } from "react-icons/lia";

import { SettingsGroup, Control } from './General'
import ScalarBox from "../../SpiceUI/ScalarBox"


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export function ColorPicker( props )
{
    const color = props.color 
    const setColor = props.setColor 
    
    const [ value, setValue ] = useState(color)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div style={{ width:'100%', height:'30px', borderRadius:'5px', 
                          border:'solid 1px #DADADA',
                          background:`rgb(${color.r} ${color.g} ${color.b})`}}
                 onClick={handleClick}
            >
            </div>
            <Menu anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}>
                <MenuItem  disableRipple>
                    <RgbaColorPicker color={color} onChange={setColor} />
                </MenuItem>
            </Menu>
        </>
    )
}


export default function FontModule( props )
{
    const font = props.font
    const setFont = props.setFont
    const selected = props.selected

    const [ fontFace, setFontFace ] = useState(null)
    const [ fontHeight, setFontHeight ] = useState(null)
    const [ fontStyle, setFontStyle ] = useState(null)
    const [ textAlignment, setTextAlignment ] = useState(null)
    const [ color, setColor ] = useState(null)

    const loaded = useRef(false)

    const reload = () => {
        if( font == null )
        {
            return
        }
        
        setFontFace( (font.font_face) ? font.font_face : "Arial" )
        setFontHeight( (font.font_height) ? font.font_height : 20 )
        setFontStyle( (font.font_style) ? font.font_style : 'regular' )
        setTextAlignment( (font.text_alignment) ? font.text_alignment : 'center' )
        setColor( (font.color) ? font.color : {r:0,g:0,b:0,a:1} )

        loaded.current = true
    }

    useEffect( () => {
        loaded.current = false
    },[selected])

    useEffect( () => {
        if( loaded.current )
        {
            return
        }

        reload()
    },[font])

    useEffect( () => {

        if( fontFace == null || fontHeight == null || fontStyle == null || textAlignment == null || color == null )
        {
            return
        }

        let f = {}
        f.font_face = fontFace 
        f.font_height = fontHeight 
        f.font_style = fontStyle 
        f.text_alignment = textAlignment 
        f.color = color

        setFont(f)

    },[fontFace, fontHeight, fontStyle, textAlignment, color ] )

    if( fontFace == null || fontHeight == null || fontStyle == null || textAlignment == null || color == null )
    {
        return ( <></>)
    }

    return (
        <SettingsGroup name="Font">
            <Control name="Font face"
                     ctrl={
                        <Form.Select style={{ width:'100%'}} size="sm" 
                             value={fontFace} 
                             onChange={ e => setFontFace( e.target.value )}
                        >
                            <option value="Arial">Arial</option>
                        </Form.Select>
                     }
            />
            <Control name="Font height"
                     ctrl={
                        <ScalarBox value={fontHeight}  
                           setValue={setFontHeight}
                           step={1}
                           labelValue="auto"
                           />
                        }
            />
            <Control name="Font style"
                     ctrl={
                        <Form.Select style={{ width:'100%'}} size="sm" 
                             value={fontStyle} 
                             onChange={ e => setFontStyle( e.target.value )}
                        >
                            <option value="regular">Regular</option>
                            <option value="bold">Bold</option>
                        </Form.Select>
                     }
            />

            <Control name="Alignment"
                     ctrl={
                        <Form.Select style={{ width:'100%'}} size="sm" 
                             value={textAlignment} 
                             onChange={ e => setTextAlignment( e.target.value )}
                        >
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                        </Form.Select>
                     }
            /> 
            { color &&
            <Control name="Color"
                    ctrl={
                        <ColorPicker color={color} setColor={setColor} />
                    }
            />
            }
        </SettingsGroup>
    )
}

