import { useState, useEffect } from "react"
import { TitledBox } from "./Misc"

import DynoProperties from "./Properties/DynoProperties"
import TextProperties from "./Properties/TextProperties"
import AvatarProperties from "./Properties/AvatarProperties"
import EmoticonProperties from "./Properties/EmoticonProperties"
import ImageProperties from "./Properties/ImageProperties"

import ShakerEffect from './Effects/ShakerEffect'
import ScaleEffect from "./Effects/ScaleEffect";
import RotationEffect from './Effects/RotationEffect'


export function Properties( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType

    const [ type, setType ] = useState("")
    const [ reset, setReset ] = useState(false)

    useEffect( () => {
        if( !layersCfg.current[selected] )
        {
            setType("")
            return
        }

        setType( layersCfg.current[selected].type )
        setReset( !reset )
    },[selected])

    if( type == "dyno" )
    {
        return (
            <TitledBox title="Properties">
                <DynoProperties layers={layers}
                                setLayers={setLayers}
                                layersCfg={layersCfg}
                                selected={selected}
                                selectedIdx={selectedIdx}
                                modalOpen={modalOpen}
                                accountType={accountType} 
                                reset={reset}
                />
            </TitledBox>
        )
    }

    if( type == "text" )
    {
        return (
            <TitledBox title="Properties">
                <TextProperties layers={layers}
                                setLayers={setLayers}
                                layersCfg={layersCfg}
                                selected={selected}
                                selectedIdx={selectedIdx}
                                modalOpen={modalOpen}
                                accountType={accountType}
                                reset={reset}
                />
            </TitledBox>
        )
    }

    if( type == "avatar" )
    {
        return (
            <TitledBox title="Properties">
                <AvatarProperties layers={layers}
                                  setLayers={setLayers}
                                  layersCfg={layersCfg}
                                  selected={selected}
                                  selectedIdx={selectedIdx}
                                  modalOpen={modalOpen}
                                  accountType={accountType} 
                                  reset={reset}
                />
            </TitledBox>
        )
    }

    if( type == "emoticon" )
    {
        return (
                <TitledBox title="Properties">
                    <EmoticonProperties layers={layers}
                                setLayers={setLayers}
                                layersCfg={layersCfg}
                                selected={selected}
                                selectedIdx={selectedIdx}
                                modalOpen={modalOpen}
                                accountType={accountType} 
                                reset={reset}
                    />
                </TitledBox>
        )
    }

    return (
        <>
        </>
    )

}

export function Effects( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType

    const [ type, setType ] = useState("")

    useEffect( () => {
        if( !layersCfg.current[selected] )
        {
            setType("")
            return
        }

        setType( layersCfg.current[selected].type )
    },[selected])

    if( type == "image" )
    {
        return (
            <TitledBox title="Effects">
                <ShakerEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg} 
                              selected={selected} selectedIdx={selectedIdx} />
                <RotationEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg} 
                                selected={selected} selectedIdx={selectedIdx} />
                <ScaleEffect layers={layers} setLayers={setLayers} layersCfg={layersCfg}
                             selected={selected} selectedIdx={selectedIdx} />
            </TitledBox>
        )
    }

    return (
        <></>
    )
}

export default function Sidebar( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const accountType = props.accountType

    return (
        <div style={{ height:'100%', 
                      width:'100%', 
                      overflowY:'scroll', 
                      overflowX:'hidden',
                      padding:'10px' }}
        >
            <Properties layers={layers}
                        setLayers={setLayers}
                        layersCfg={layersCfg}
                        selected={selected}
                        selectedIdx={selectedIdx}
                        modalOpen={modalOpen}
                        accountType={accountType}
            />
            <Effects layers={layers}
                     setLayers={setLayers}
                     layersCfg={layersCfg}
                     selected={selected}
                     selectedIdx={selectedIdx}
                     modelOpen={modalOpen}
                     accountType={accountType}
            />
        </div>
    )
}
