
import { FaCheckCircle } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import { FaCircle } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";


export default function Checkmark( props )
{
    const valid = props.valid 
    const setValid = props.setValid
    const name = props.name
    
    return (
        <div className="d-flex justify-content-start" style={{ marginBottom: (valid) ? '5px' : '5px', width:'100%' }}>
            {valid ? 
                <div style={{ marginLeft:'10px'}}>
                    <FaCheckCircle onClick={e=>setValid(!valid)} color="0696FF" cursor="pointer" /> {name}
                </div>
                :
                <div style={{ marginLeft:'10px'}}>
                    <FaPlusCircle  onClick={e=>setValid(!valid)} cursor="pointer"/> {name}
                </div>
            }
        </div>

    )
}
