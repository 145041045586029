
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'


export function Control( props )
{
    const name = props.name 
    const ctrl = props.ctrl
    return (
        <div style={{ width:'100%', height: '31px', fontSize:'12px', backgroundColor:'#FFF', marginBottom:'5px'}} >
            <div className="d-flex justify-content-start align-items-center" 
                 style={{ width: '80px', float: 'left', height:'100%' }} 
            >
                { name }
            </div>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{ width:'calc( 100% - 80px )', height:'100%', float:'right' }}>
                { ctrl } 
            </div>

        </div>
    )
}

export function VariableControl( props )
{
    const name = props.name 
    const value = props.value 
    const setValue = props.setValue
    const type = props.type

    return (
        <div style={{ width:'100%', height: '31px', fontSize:'12px', backgroundColor:'#FFF', marginBottom:'5px'}} >
            <div className="d-flex justify-content-start align-items-center" 
                 style={{ width: '80px', float: 'left', height:'100%' }} 
            >
                { name }
            </div>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{ width:'calc( 100% - 80px )', height:'100%', float:'right' }}>
                <InputGroup size="sm">
                    <Form.Control value={value} 
                                  onChange={e => setValue(e.target.value)} 
                                  size="sm" 
                    />
                    <InputGroup.Text>{type}</InputGroup.Text>
                </InputGroup>
            </div>
        </div>
    )
}
