
import { useState, useEffect } from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'

import { backendAccessGet, backendAccessPost } from "../../Utils/Authentication"

export function CreateLotteryModal( props )
{
    const showModal = props.showModal
    const setShowModal = props.setShowModal
    const createLottery = props.createLottery

    const [ name, setName ] = useState("")
    const [ value, setValue ] = useState(0)

    useEffect( () => {
        if( showModal )
        {
            setName("")
            setValue(0)
        }
    },[showModal])

    const submitModal = () => {
        console.log( name, value )
        if( name == "" || value == 0 )
        {
            return
        }

        let dd = {}
        dd.name = name 
        dd.ticket_value = value 

        createLottery( dd ).then( res => {
            setShowModal(false)
        })
    }
    
    return (
        <Modal show={showModal} onHide={ e => setShowModal(false)}>
            <Modal.Header closeButton>
                Create new lottery
            </Modal.Header>
            <Modal.Body>
                <div style={{ padding:'15px', width:'100%' }}>
                    <Row style={{ marginBottom:'5px'}}>
                        Lottery name
                    </Row>
                    <Row style={{ marginBottom:'10px'}}>
                        <Form.Control value={name} onChange={ e => setName(e.target.value)}/>
                    </Row>
                    <Row style={{ marginBottom:'5px'}}>
                        Ticket Value
                    </Row>
                    <Row style={{ marginBottom:'10px'}}>
                        <Form.Control value={value} onChange={ e => setValue(parseInt(e.target.value))}/>
                    </Row>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button style={{ textTransform:'none' }} 
                        variant="contained"
                        size="small"
                        onClick={submitModal}
                >
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export function LotteryListViewer( props )
{
    const title = props.title
    const lotteries = props.lotteries

    if( lotteries == null )
    {
        return (<></>)
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{width:'100%', height:'50px'}}>
                <b>{ title }</b>
            </div>
            { lotteries.map( (l,idx) =>
            <div key={idx} style={{ padding:'10px', backgroundColor:'lightgreen', borderRadius:'5px'}}>
                <div>
                    {l.name} - {l.lottery_id} - {l.status}
                </div>
                <div>
                    {l.owner} - {l.ticket_value}
                </div>
            </div>
            )}
        </>
    )
}

export function TicketListViewer( props )
{
    const title = props.title  
    const tickets = props.tickets 

    if( tickets == null )
    {
        return (<></>)
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{width:'100%', height:'50px'}}>
                <b>{ title }</b>
            </div>
            { tickets.map( (t,idx) =>
            <div key={idx} style={{ padding:'10px', backgroundColor:'lightgreen', borderRadius:'5px'}}>
            </div>
            )}
        </>
    )
}

export function EnroleLotteryViewer( props )
{
    const title = props.title 
    const lotteries = props.lotteries 
    const setTickets = props.setTickets

    if( lotteries == null )
    {
        return (<></>)
    }

    const getTicket = ( uid ) => {
        let data = {}
        data.lottery_id = uid 

        backendAccessPost('/api/lottery/get-ticket/', data ).then( res => {
            if( res.has_error )
            {
                alert( res.has_error )
                return
            }
        })
    }

    return (
        <>
            <div className="d-flex justify-content-center align-items-center" 
                 style={{width:'100%', height:'50px'}}>
                <b>{ title }</b>
            </div>
            { lotteries.map( (l,idx) =>
            <div key={idx} style={{ padding:'10px', backgroundColor:'lightblue', borderRadius:'5px'}}>
                <div style={{ marginBottom:'10px'}}>
                    {l.name} - {l.lottery_id} - {l.owner}
                </div>
                <div>
                    <Button style={{ textTransform:'none' }}
                            variant="contained"
                            size="small"
                            onClick={e => getTicket(l.lottery_id)}
                    >
                        Get ticket
                    </Button>
                </div>
            </div>
            )}
        </>
    )
}

export default function Lottery( props )
{
    const [ myLotteries, setMyLotteries ] = useState(null)
    const [ myTickets, setMyTickets ] = useState(null)
    const [ activeLotteries, setActiveLotteries ] = useState(null)

    const [ showCreateModal, setShowCreateModal ] = useState(false)

    useEffect( () => {
        backendAccessGet('/api/lottery/fetch/').then( res => {
            if( res.has_error )
            {
                alert( res.has_error )
                return
            }

            setMyLotteries( res.my_lotteries )
            setMyTickets( res.my_tickets )
            setActiveLotteries( res.active_lotteries )
        })
    },[]) 

    const createLottery = async ( data ) => { 
        backendAccessPost('/api/lottery/create/', data).then( res => {
            if( res.has_error )
            {
                alert( res.has_error )
                return 
            }

            setMyLotteries( res.my_lotteries )
            setMyTickets( res.my_tickets )
            setActiveLotteries( res.active_lotteries )
        }) 
    }

    return (
        <>
            <Container style={{ maxWidth:'800px', height:'100%', 
                                overflowY:'scroll', padding:'10px'}}>
                <div style={{ width:'100%'}} >
                    <Button style={{ textTransform:'none' }} 
                            variant="contained"
                            onClick={e => setShowCreateModal(true)}>Create Lottery</Button>
                </div>
                <LotteryListViewer title="My Lotteries" 
                                   lotteries={myLotteries}
                />
                <TicketListViewer title="My Tickets" 
                                  tickets={myTickets}
                />
                <EnroleLotteryViewer title="Active Lotteries" 
                                     lotteries={activeLotteries}
                                     setTickets={setMyTickets}
                                     
                />
                </Container>
            <CreateLotteryModal showModal={showCreateModal} 
                                setShowModal={setShowCreateModal}
                                createLottery={createLottery}
            />
        </>
    )
}
